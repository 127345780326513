<template>
  <div>
    <div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-3">
          <h3 class="pt-5">
            Reset password
          </h3>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-3">
          <p>Please enter your new password below.</p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-3">
          <input
            v-model="password"
            class="sign-up-form-input"
            type="password"
            required
            placeholder="Password" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pb-10">
        <div class="cell small-12 medium-3 medium-offset-3">
          <button @click="reset" class="button expanded">Send</button>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="grid-x grid-padding-x pb-10">
      <div class="cell small-12 medium-6 medium-offset-3">
        <div
          @click="message = ''"
          class="message">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      message: '',
    };
  },
  methods: {
    reset() {
      const postData = {};
      postData.password = this.password;
      const hashTest = this.$route.params.hash;
      const customerId = this.$route.params.id;
      axios.post(`/users/resetPassword/${hashTest}/${customerId}.json`, postData)
        .then((response) => {
          this.message = response.data;
          this.$router.push('/login');
        });
    },
  },
};
</script>
